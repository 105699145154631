<template>
  <b-card
    :header="$i18n.t('orders.orders')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <div class="d-flex align-items-center justify-content-start mb-1">
      <!-- filters -->
      <FiltersAndSearch
          :id.sync="id"
          :searchKey.sync="searchKey"
          :primaryStatus.sync="status"
          :primaryStatusFields="orderStatus"
          :startDate.sync="startDate"
          :endDate.sync="endDate"
          :disableActiveOnly="true"
      />
      <b-button
          title="Refetch data"
          variant="primary"
          class="mr-1 p-0-8"
          @click="fetchOrders()"
      >
        <feather-icon icon="SearchIcon"></feather-icon>
      </b-button>

      <!-- end filter -->
      <!-- export -->
      <ExportFromTo
          title="Export to excel"
          :exportHandler="exportOrdersHandler"
          :hasDateFilter="false"
          :filtersVisible="false"
          :iconOnly="true"
          :disabled="ordersList.length === 0"
      />
    </div>

    <!-- end export -->
    <div v-if="false" class="custom-search">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <b-table
        sticky-header
        bordered
        hover
        size="sm"
        responsive
        class="shadow-sm rounded products-table orders-table"
        thead-tr-class="order-main-thead"
        :items="ordersList"
        :fields="fields"
        :busy="isTableBusy"
        primary-key="id"
        show-empty
        :empty-text="$t('global.no-items')"
    >
      <template #cell(status)="data">
        <div class="d-flex align-items-center">
          <b-form-select
              v-model="data.item.status"
              value-field="value"
              text-field="text"
              class="mr-1"
              size="sm"
              :options="computedOptions(data.item)"
          />
          <b-button
              size="sm"
              variant="success"
              :disabled="data.item.status != 1 && data.item.status != 2"
              @click="changeStatus(data.item)"
          >
            <feather-icon size="10" icon="SaveIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-table
        v-if="false"
      bordered
      sticky-header
      hover
      responsive
      class="shadow-sm rounded products-table orders-table"
      thead-tr-class="order-main-thead"
      :items="ordersList"
      :busy="isTableBusy"
      :fields="fields"
      primary-key="id"
      show-empty
      :empty-text="$t('global.no-items')"
      @row-clicked="onRowClicked"
    />
    <Pagination
      :fetch-data="fetchOrders"
      :skip.sync="skip"
      :total-rows="totalRows"
      :take.sync="take"
    />
  </b-card>
</template>

<script>
import { BTable, BCard, BFormInput, BFormGroup, BButton, BFormSelect } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Pagination from "@/views/components/Pagination/Pagination.vue";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    BFormSelect,
    BButton,
    BTable,
    BCard,
    BFormInput,
    BFormGroup,
    Pagination,
    ExportFromTo,
    FiltersAndSearch,
  },
  data() {
    return {
      ordersList: [],
      totalRows: 0,
      skip: 0,
      take: 10,
      sortOrder: 2,
      searchKey: "",
      status: "",
      id: "",
      startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      // startDate: "2023-11-01",
    };
  },

  computed: {
    orderStatus() {
      return {
        0: this.$i18n.t("orders.preOrdered"),
        8: this.$i18n.t("orders.ordered"),
        1: this.$i18n.t("orders.ready"),
        2: this.$i18n.t("orders.taken"),
        11: this.$i18n.t("orders.finished"),
        12: this.$i18n.t("orders.canceled"),
      };
    },
    paymentStatus() {
      return {
        0: this.$i18n.t("orders.waiting"),
        1: this.$i18n.t("orders.failed"),
        2: this.$i18n.t("orders.paid"),
        3: this.$i18n.t("orders.refunded"),
        4: this.$i18n.t("orders.partiallyRefunded"),
      };
    },
    orderItemStatus() {
      return [
        { value: 0, text: this.$i18n.t("orders.preOrdered"), disabled: true },
        {
          value: 8,
          text: this.$i18n.t("orders.ordered"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$i18n.t("orders.ready"),
          disabled: false,
        },
        { value: 2, text: this.$i18n.t("orders.taken"), disabled: false },
        { value: 11, text: this.$i18n.t("orders.finished"), disabled: true },
        { value: 12, text: this.$i18n.t("orders.canceled"), disabled: true },
      ];
    },
    fields() {
      return [
        {
          label: this.$t("orders.status"),
          key: "status",
          formatter: (value) => this.orderItemStatus[value],
          class: "status-field",
        },
        {
          label: this.$i18n.t("orders.createdAt"),
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$t("orders.id"),
          key: "orderId",
        },
        {
          label: this.$t("orders.child-id"),
          key: "id",
        },
        {
          label: this.$t("orders.activationCode"),
          key: "activationCode",
        },
        {
          label: this.$t("products.name"),
          key: "name",
          sortable: false,
        },
        {
          label: this.$t("orders.p-id"),
          key: "productId",
          formatter: (value) => value,
        },
        {
          label: this.$t("orders.endDate"),
          key: "updatedAt",
          formatter: (value) => `${value || ''}`,
        },
        {
          label: this.$t("orders.productPrice"),
          key: "price",
          formatter: (value) => `${value?.toFixed(2)} ₾`,
        },
        {
          label: this.$t("orders.emotyFee"),
          key: "emotyFee",
          formatter: (value) => `${value?.toFixed(2)} ₾`,
        },
        {
          label: this.$t("orders.moneyDue"),
          key: "moneyDue",
          formatter: (value) => `${value?.toFixed(2)} ₾`,
        },
        {
          label: this.$t("orders.receiverName"),
          key: "receiverFullName",
        },
        {
          label: this.$t("orders.receiverMobile"),
          key: "receiverMobile",
        },
        {
          label: this.$t("orders.receiverDeliveryAddress"),
          key: "receiverAddress",
        },
        {
          label: this.$t("orders.rating"),
          key: "rating",
        },
        {
          label: this.$t("orders.review"),
          key: "review",
        },
      ];
    },
    ...mapState("ordersModule", ["orders", "isTableBusy"]),
  },
  watch: {
    searchKey() {
      this.fetchOrders();
    },
    status() {
      this.fetchOrders();
    },
    id() {
      this.fetchOrders();
    },
    startDate() {
      this.fetchOrders();
    },
    endDate() {
      this.fetchOrders();
    },
    take() {
      this.fetchOrders();
    },
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    ...mapActions("ordersModule", [
      "getOrders",
      "getOrderById",
      "exportOrders",
      "updateOrderStatus"
    ]),
    async fetchOrders() {
      await this.getOrders({
        skip: this.skip,
        take: this.take,
        searchKey: this.searchKey,
        status: this.status,
        sortOrder: this.sortOrder,
        id: this.id,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.totalRows = this.orders.count;
      this.ordersList = [];
      this.orders.orders.forEach((el) => {
        el.items.forEach((item) => {
          const order = {
            status: item.status,
            orderId: el.id,
            createdAt: el.createdAt,
            id: item.id,
            activationCode: item.activationCode,
            productId: item.product.id,
            name: `${item.product.nameGeo}  / ${item.product.nameEng}`,
            emotyFee: item.product.emotyFee,
            moneyDue: item.product.moneyDue,
            price: item.product.productPrice,
            endDate: el.endDate,
            updatedAt: item.updatedAt,
            receiverFullName: el.receiverFullName,
            receiverAddress: el.deliveryAddress,
            receiverMobile: el.receiverMobile,
            rating: item.feedBackValue,
            review: item.feedBackText,
          }
          this.ordersList.push(order)
        })
      })
      this.skip = this.orders.skip;
      this.take = this.orders.take;
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    async exportOrdersHandler() {
      const payload = {
        skip: 0,
        take: this.totalRows,
        searchKey: this.searchKey,
        status: this.status,
        sortOrder: this.sortOrder,
        id: this.id,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      try {
        const response = await this.exportOrders(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Orders-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    onRowClicked(item) {
      router.push(`/orders/${item.id}`);
    },
    computedOptions(item) {
      return this.orderItemStatus.map((status) => ({
        ...status,
        disabled: status.disabled || item.status >= 11,
      }));
    },
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    changeStatus(item) {
      const payload = {
        orderId: item.orderId,
        orderItemId: item.id,
        statusObject: {
          status: parseFloat(item.status),
        },
      };

      this.updateOrderStatus(payload).then((res) => {
        if (res.data.error) {
          this.showToast(res.data.error.message, "danger");
        } else {
          this.showToast("Status Changed", "success");
          this.refetchMethod();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}

.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}

.pointer {
  cursor: pointer;
}

.p-0-8 {
  padding: 0.8rem
}
</style>
